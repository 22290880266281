import { createStore } from "vuex";

const baseStorageUri =
  "https://storage.gra.cloud.ovh.net/v1/AUTH_0697890e15ad4098b140de1fed916171/maudgourdon.com";
export default createStore({
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
  state: {
    baseStorageUri,
    MenuOrder: [
      21, 20, 19, 18, 17, 16, 15, 12, 13, 14, 11, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    ],
    homepage: [
      { id: 21, image: 1 },
      { id: 20, image: 1 },
      { id: 19, image: 1 },
      { id: 18, image: 1 },
      { id: 17, image: 4 },
      { id: 21, image: 2 },
      { id: 15, image: 1 },
      { id: 19, image: 3 },
      { id: 16, image: 1 },
      { id: 12, image: 1 },
      { id: 8, image: 3 },
      { id: 2, image: 2 },
      { id: 21, image: 4 },
      { id: 11, image: 4 },
      { id: 18, image: 4 },
      { id: 15, image: 15 },
      { id: 6, image: 1 },
      { id: 20, image: 4 },
      { id: 6, image: 7 },
      { id: 21, image: 6 },
      { id: 19, image: 9 },
      { id: 3, image: 3 },
      { id: 14, image: 2 },
      { id: 15, image: 5 },
      { id: 16, image: 5 },
      { id: 10, image: 1 },
      { id: 6, image: 2 },
      { id: 10, image: 2 },
      { id: 11, image: 8 },
      { id: 18, image: 12 },
      { id: 21, image: 7 },
      { id: 3, image: 5 },
      { id: 20, image: 2 },
      { id: 15, image: 7 },
      { id: 11, image: 5 },
      { id: 6, image: 3 },
      { id: 8, image: 2 },
      { id: 9, image: 3 },
      { id: 13, image: 1 },
      { id: 21, image: 9 },
      { id: 15, image: 6 },
      { id: 20, image: 3 },
      { id: 6, image: 4 },
      { id: 3, image: 2 },
      { id: 21, image: 10 },
    ],
    articles: [
      {
        id: 1,
        title: "Ciel vide",
        date: 2018,
        long_description: `Mural painting`,
        images: ["/images/CV_1.png", "/images/CV_2.png"],
      },
      {
        id: 2,
        title: "Grâce au travail de ceux qui espèrent",
        date: 2018,
        long_description: `series of drawings about friendship`,
        images: [
          "/images/GATDCQP_1.png",
          "/images/GATDCQP_2.png",
          "/images/GATDCQP_3.png",
          "/images/GATDCQP_4.png",
        ],
      },
      {
        id: 3,
        title: "Protocole for Unexpected Dancer",
        date: "2017-2018",
        long_description: `seven pop songs and seven weather forecasts`,
        images: [
          "/images/PfUD_1.png",
          "/images/PfUD_2.png",
          "/images/PfUD_3.png",
          "/images/PfUD_4.png",
          "/images/PfUD_5.png",
        ],
      },
      {
        id: 4,
        title: "BM&Y",
        date: 2017,
        long_description: `six offended love letters`,
        images: ["/images/BMY_1.png", "/images/BMY_2.png", "/images/BMY_3.png"],
      },
      {
        id: 5,
        title: "M-587",
        date: 2017,
        long_description: `performance for two readers based on the score of a weaving pattern`,
        extended: true,
        images: ["/images/M587_1.png"],
      },
      {
        id: 6,
        title: "Peinée l'eau peut",
        date: 2017,
        long_description: `visual song distorting the pattern of Ulysses's and Penelope's character and love story, as well as the notion of rhythm and time`,
        images: [
          "/images/PEP_1.png",
          "/images/PEP_2.png",
          "/images/PEP_3.png",
          "/images/PEP_4.png",
          "/images/PEP_5.png",
          "/images/PEP_6.png",
          "/images/PEP_7.png",
          "/images/PEP_8.png",
        ],
      },
      {
        id: 7,
        title: "Seven Songs",
        date: 2017,
        long_description: `seven pop songs and seven weather forecasts`,
        image_count: 4,
        images: [
          "/images/7S_1.png",
          "/images/7S_2.png",
          "/images/7S_3.png",
          "/images/7S_4.png",
        ],
      },
      {
        id: 8,
        title: "A.MonSeulDésir.R",
        date: 2016,
        long_description: `eighteen flowers vinyl stickers and a frieze using a pattern derived from the Jacquard knitting technique.`,
        images: [
          "/images/AMSDR_1.png",
          "/images/AMSDR_2.png",
          "/images/AMSDR_3.png",
          "/images/AMSDR_4.png",
          "/images/AMSDR_5.png",
        ],
      },
      {
        id: 9,
        title: "EXOACTO",
        date: 2016,
        long_description: `series of drawings with an appel and a knife`,
        extended: true,
        images: [
          "/images/Exoacto_1.png",
          "/images/Exoacto_2.png",
          "/images/Exoacto_3.png",
          "/images/Exoacto_4.png",
        ],
      },
      {
        id: 10,
        title: "Sun is raising twice",
        date: 2015,
        long_description: `series of drawing with a window and a rose`,
        images: [
          "/images/Sunisraisingtwice_1.png",
          "/images/Sunisraisingtwice_2.png",
          "/images/Sunisraisingtwice_3.png",
          "/images/Sunisraisingtwice_4.png",
        ],
      },
      {
        id: 11,
        title: "A Flower is Speaking to a Dog",
        date: 2019,
        description: "",
        long_description: `<p>A Flower is Speaking to a Dog is the result of Gourdon’s fascination with and 
        study of DNA as a form within which a language can be built.</p>
        <p>The publication consists of a set of generative texts in which the genetic sequence of DNA functions 
        as an underlying structure or a partite for the characters.</p><br />
        Picture credits: <i>Rachel Gruijters</i> & <i>Leontien Allemeersch</i>`,
        images: [
          "/images/BOEKS_1.png",
          "/images/BOEKS_2.png",
          "/images/BOEKS_3.png",
          "/images/BOEKS_4.png",
          "/images/BOEKS_5.png",
          "/images/BOEKS_6.png",
          "/images/BOEKS_7.png",
          "/images/BOEKS_8.png",
          "/images/BOEKS_9.png",
          "/images/BOEKS_10.png",
          "/images/BOEKS_11.png",
          "/images/BOEKS_12.png",
          "/images/BOEKS_13.png",
        ],
      },
      {
        id: 12,
        title: "Pains Surprises",
        date: 2019,
        description: "Surprised Pains or Bread on the Power Socket",
        long_description: `The fictional texts developed in Pains Surprises strive to be untranslatable, as it is 
        precisely when tongues and languages slip that fictions start. Here, the language is used as an active 
        substance - a « pharmakon » : an ambivalent environment where nothing is what it is expected and where 
        everything is in transformation, in translation.`,
        images: [
          "/images/PS_1.png",
          "/images/PS_2.png",
          "/images/PS_3.png",
          "/images/PS_4.jpeg",
        ],
      },
      {
        id: 13,
        title: "Domestic Echo",
        date: 2019,
        description: "",
        long_description: `<p>Projet in collaboration with Chantal van Rijt. The exhibition renders the idea of 
        an interconnected universe where data is used as a departure point for robotic tales and poetry. 
        Via simple mechanisms of loops and repetitions, Chantal van Rijt and Maud Gourdon generate visual and 
        linguistic scores through which objects, machines and humans interact in a joyful and choreographed chaos.</p>
        
        <p><a class="underlined" href="${baseStorageUri}/files/DomesticEcho_KoiPersyn.pdf" target="_BLANK">Full text about the show by Koi Persyn</a><br />
        credit photo : Chantal van Rijt</p>`,
        images: [
          "/images/DE_1.png",
          "/images/DE_2.png",
          "/images/DE_3.png",
          "/images/DE_4.png",
          "/images/DE_5.png",
          "/images/DE_6.png",
          "/images/DE_7.png",
          "/images/DE_8.png",
          "/images/DE_9.png",
        ],
      },
      {
        id: 14,
        title: "A good-talking candle",
        description: "",
        long_description: `<p>Reading in group by candlelight in the context of BOEKS 04 : A Flower is Speaking to Dog. 
        A good-talking candle is an invitation to dive into a selection of stories about multispecies. 
        This assemblage of texts from various sources claim a desire for transformation by the use of 
        fiction as a reservoir for thought experiments.</p><br />
        
        <p>credit photo : Rachel Gruijters</p>`,
        images: ["/images/AGTC_2.png", "/images/AGTC_1.png"],
      },
      {
        id: 15,
        title: "Therolinguistic Tale, a verse of wormdots and seedlines",
        description: "",
        long_description: `<p>Project with the artist Chantal van Rijt.<br />
        During a five-month residency, Maud Gourdon and Chantal van Rijt explored the concepts of coding and decoding. Working with traces of beetles and dried seeds they attempted to decipher messages of tunnels, holes and dots. In the exhibition, Maud and Chantal consider plants and animals as our linguistic equals as they search for ways to translate their hidden and discarded language.</p>
        <a style='text-decoration: underline;' href="${baseStorageUri}/files/TherolinguisticTale/Interview_M_Magazine.pdf" target="_BLANK">Interview M-Magazine</a><br />
        photo credits : Chantal van Rijt`,
        images: [
          "/images/Therolinguist_1.jpg",
          "/images/Therolinguist_2.jpg",
          "/images/Therolinguist_3.jpg",
          "/images/Therolinguist_4.jpg",
          "/images/Therolinguist_5.jpg",
          "/images/Therolinguist_6.jpg",
          "/images/Therolinguist_7.jpg",
          "/images/Therolinguist_8.jpg",
          "/images/Therolinguist_9.jpg",
          "/images/Therolinguist_10.jpg",
          "/images/Therolinguist_11.jpg",
          "/images/Therolinguist_12.jpg",
          "/images/Therolinguist_13.jpg",
          "/images/Therolinguist_14.jpg",
          "/images/Therolinguist_15.jpg",
          "/images/Therolinguist_16.jpg",
        ],
      },
      {
        id: 16,
        title: "Seven Short Stories About Nobody",
        description: "",
        long_description: `<p>Published in the collective publication 'Family Nexus'with the artists Liene Aerts, Sophie Nys and Leila Peacock.
        The term "Family Nexus" was coined by the Scottish anti-psychiatrist R.D. Laing, who researched and wrote extensively about schizophrenia which he described as "a lonely journey to the primitive point of unity" where patients "seem to experience a primordial chaos, where nothing can be distinguished from others and named with precision". This description reminded me of some spiritual journeys.</p>
        <p>"Seven Short Stories About Nobody" explores themes common to schizophrenic and spiritual journeys: name change, loss or duplication of identity, objectification, feelings of chaos and death (...)</p>
        <p><a href="https://kiosk.art/family-nexus-launch">https://kiosk.art/family-nexus-launch</a><br />
        <a href="https://www.rile.space/books/family-nexus">https://www.rile.space/books/family-nexus</a><br />
        photo credits: Chantal van Rijt</p>`,
        images: [
          "/images/Family_Nexus_1_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_2_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_3_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_4_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_5_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_6_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_7_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_8_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_9_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_10_crédit_ChantalvanRijt.jpg",
          "/images/Family_Nexus_11_crédit_ChantalvanRijt.jpg",
        ],
      },
      {
        id: 17,
        title: "Chambres d'aimé.e.s",
        description: "",
        long_description: `<p>Series of 16 booklets and 32 plotter drawings</p>
        photo credits: Chantal van Rijt</p>`,
        images: [
          "/images/Chambre_1.jpg",
          "/images/Chambre_2.jpg",
          "/images/Chambre_3.jpg",
          "/images/Chambre_4.jpg",
          "/images/Chambre_5.jpg",
          "/images/Chambre_6.jpg",
          "/images/Chambre_7.jpg",
          "/images/Chambre_8.jpg",
          "/images/Chambre_9.jpg",
          "/images/Chambre_10.jpg",
          "/images/Chambre_11.jpg",
          "/images/Chambre_12.jpg",
        ],
      },
      {
        id: 18,
        title: "Cataplasme !",
        description: "",
        long_description: `<p>The new body of work presented in the exhibition stems from Maud Gourdon’s experiments with the cataplasm, also known as a plaster: a pasty remedy made from clay, plants or flours, which the artist uses as a sculptural and ornamental material.</p><p>Nowadays being relegated to the category of “grandmother’s” remedies, the cataplasm is regarded by the artist as related to the mother figure and to the knowledge that is traditionally passed on from mother to daughter: manual work, cooking, sewing, interior decoration, remedies, and the "notion of taking care of,  etc.</p><p><a style='text-decoration: underline;' href="${baseStorageUri}/files/Cataplasme_Texte_expo_Liene_Aerts.pdf" target="_blank">Exhibition text written by Liene Aerts</a><br /><a style='text-decoration: underline;' href="${baseStorageUri}/files/Press_Release_Cataplasme_Maud_Gourdon.pdf" target="_blank">Press release: Hart Magazine, Rekto:Verso</a><br />Photo credits: Regular Studio and Chantal van Rijt</p>`,
        images: [
          "/images/Cataplasme_1.jpg",
          "/images/Cataplasme_2.jpg",
          "/images/Cataplasme_3.jpg",
          "/images/Cataplasme_4.jpg",
          "/images/Cataplasme_5.jpg",
          "/images/Cataplasme_6.jpg",
          "/images/Cataplasme_7.jpg",
          "/images/Cataplasme_8.jpg",
          "/images/Cataplasme_9.jpg",
          "/images/Cataplasme_10.jpg",
          "/images/Cataplasme_11.jpg",
          "/images/Cataplasme_12.jpg",
          "/images/Cataplasme_13.jpg",
          "/images/Cataplasme_14.png",
          "/images/Cataplasme_15.png",
          "/images/Cataplasme_16.png",
          "/images/Cataplasme_17.png",
          "/images/Cataplasme_18.png",
        ],
      },
      {
        id: 19,
        title: "Chier temps*temps chers",
        description: "",
        long_description: `<p>The project is based on the artist's research into the San Francisco Diggers, a group of radical activists in the mid-60s who challenged norms and inspired change through free food and goods, street theater and community action. The artist examines the dispersion of forms that SF Diggers have generated and inherited throughout history and in the current context of advanced capitalism.</p><p>The audience is invited to interact with an environment congregating various objects mirroring her critical way of analysing the twists sometimes history can produce.</p><p>The installation presents the works <i>Swap Shop</i>, a tribute to the SF Diggers' free stores, as well as <i>Un pour cent</i>, two textile quilts inspired by the SF Diggers' slogan "1% free". The installation is complemented by two papier-mâché sculptures, inspired by Carlo Bugatti's Cobra chair, which serve as chairs and platforms for reading the books ’News from Nowhere’ by William Morris and ’Ringolevio’ by San Francisco activist Emmett Grogan.</p><br /><p>Presented as part of the collective exhibition “Worldlines” organised by Sébastien Pluot at HISK - Gosset (Brussels), 2023<br />Photo credits : vandenbussche-vandenbossche</p>`,
        images: [
          "/images/1_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/2_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/3_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/4_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/5_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/6_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/7_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/8_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/9_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/10_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/11_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/12_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/13_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
          "/images/14_Chier_temps_Maud_Gourdon_(c)vandenbussche-vandenbossche.jpg",
        ],
      },
      {
        id: 20,
        title: "Una montaña de letras",
        description: "",
        long_description: `<p>Inspired by the legacy of the San Francisco Diggers, Juan Pablo Plazas and Maud Gourdon embark on a ticketless literary journey, delving into the complex interplay between 60s counterculture, activism, free economics, money, collective action, guerrilla theater, music and spirituality.</p><p>The journey begins with Juan Pablo moving house, confronted with an unmanageable mountain of objects, and continues with the exchange of a scarf for a vintage 70s button-down shirt, which reconnects Juan to his inner hippy. This is followed by a pub conversation with Maxime - Maud's lover, a financier with literary aspirations - about the fluid nature of money. This leads Juan to the familistère Godin, a former workers’ social palace juxtaposed with a brand new shopping center, and then to Copenhagen, where he discovers an old coin bearing the inscription “there is no god but God”. The journey ends with a poetry session on the phone with Luz, a person with a strange gift for finding money.</p><p>Throughout this story, which combines historical references, personal anecdotes, philosophical and metaphysical conversations and musical suggestions, the reader will discover a kaleidoscope of individuals and experiences that challenge conventional notions of society, money and spirituality.</p><br /><p>Text "Una montaña de letras" written by Juan Pablo Plazas<br />Graphic design by Maud Gourdon with the help of Camille Prandi<br />This project is part of a collection of 12 publications, published on the occasion of the Worldlines exhibition - HISK Laureates Exhibition 2023</p>`,
        images: [
          "/images/1_una_montana_de_letras.jpg",
          "/images/2_una_montana_de_letras.jpg",
          "/images/3_una_montana_de_letras.jpg",
          "/images/4_una_montana_de_letras.jpg",
        ],
      },{
        id: 21,
        title: "Cloud Cuckoo Land",
        description: "",
        long_description: `<p>This installation draws inspiration from the controversy surrounding Roger Raveel's <i>De Zwanen van Brugge</i> (<i>The Swans of Bruges</i>), which was displayed in the canals of Bruges during the 1971 Triennial. While poetic in nature, the artwork was perceived by locals and authorities as a critique of the canal’s water quality, ultimately leading to its removal.</p>
        
        <p>The story evoked connections to Peter McIndoe’s satirical <i>Birds Aren't Real</i> movement, as well as Aristophanes' classic play <i>The Birds</i>. By interweaving these narratives with Raveel's, I developed <i>Cloud Cuckoo Land</i>, an installation that blends humor, mythology, and social critique.</p>
        
        <p>This work is part of the collective exhibition <i>Scarecrow of the Museum</i>, curated by Mélanie Deboutte for the 9th Biennale of Painting, hosted at the Roger Raveel Museum (30.06.2024–06.10.2024). <br />
        <a href="https://rogerraveelmuseum.be/en/exhibition/9e-bi%C3%ABnnale-van-de-schilderkunst#:~:text=At%20the%20invitation%20of%20the,new%20work%20for%20this%20occasion" target="_blank">More info</a></p>
        
        <p>Photo credits: Lola Pertsowsky</p>`,
        images: [
          "/images/1_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/2_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/3_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/4_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/5_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/6_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/7_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/8_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/9_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/10_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/11_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/12_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/13_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
          "/images/14_CloudCuckooLand_Maud_Gourdon_(c)Lola_Pertsowsky.jpg",
        ],
      },
    ],
  },
});
